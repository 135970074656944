@import "styles/core.scss";
.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-100);
  padding-top: 0;

  @include medium-up {
    margin-bottom: var(--space-200);
    a {
      max-width: 300px;
    }
  }

  @include page-width-lg-up {
    padding-top: var(--space-600);
  }

  @include small-down {
    gap: var(--space-300);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: var(--z-index-sticky);
    text-align: center;
    padding: var(--space-200) var(--space-200) var(--space-450);
    gap: var(--space-100);
    background: var(--color-background-primary);
  }
}
