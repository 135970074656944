@import "styles/core.scss";
.locationDescription {
  @include text-200;
  margin-bottom: var(--space-600);

  button {
    @include text-200-bold;
  }

  @include page-width-sm-down {
    margin: 0 var(--space-300) var(--space-600) var(--space-300);
  }

  @include page-width-md-up {
    @include text-200;
    button {
      @include text-200-bold;
    }
  }

  @include page-width-lg-up {
    margin-bottom: 0;
  }
}

.divider {
  border-bottom: var(--borders-weight-sm) solid var(--color-border-separator);
  margin-bottom: var(--space-600);

  @include page-width-lg-up {
    display: none;
  }
}
