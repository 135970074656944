@import "styles/core.scss";
// $shadow lets us add to any existing box-shadows and defaults to no box-shadow
@mixin focusOutline($color, $shadow: 0 0) {
  &:not(.loading):focus-visible {
    box-shadow:
      $shadow,
      0 0 0 var(--borders-weight-lg) $color inset;
  }
}

@mixin activeOrHover {
  &:not(.loading):not(.disabled) {
    &:active {
      @content;
    }

    @include hover {
      @content;
    }
  }
}

.button {
  @include removeDefaultButtonStyles;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0; // Needed to remove default margin on some browsers when an anchor tag is rendered
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-round);
  cursor: pointer;
  transition: all 0.2s ease-out;

  @include focusOutline(var(--color-border-focus-default));

  &:focus,
  &:focus-visible {
    outline: none !important;
  }

  &:not(.loading):not(.disabled):active {
    transform: scale(0.96);
  }
}

.sm {
  height: 36px;
  width: 36px;

  .plusBadge {
    right: -2px;
  }
}

.md {
  height: 48px;
  width: 48px;
}

.lg {
  height: 56px;
  width: 56px;
}

.default {
  background-color: var(--color-neutral-100-a);
  color: var(--color-text-primary);

  @include activeOrHover {
    background-color: var(--color-neutral-200-a);
  }

  @include focusOutline(var(--color-border-focus-default));
}

.elevated {
  background-color: var(--color-background-primary);
  color: var(--color-text-primary);

  @include activeOrHover {
    background-color: var(--color-background-primary-hover);
  }

  &.sm {
    box-shadow: var(--shadow-100);
    @include focusOutline(var(--color-border-focus-default), var(--shadow-100));
  }

  &.md {
    box-shadow: var(--shadow-200);
    @include focusOutline(var(--color-border-focus-default), var(--shadow-200));
  }

  &.lg {
    box-shadow: var(--shadow-300);
    @include focusOutline(var(--color-border-focus-default), var(--shadow-300));
  }
}

.flat {
  background-color: transparent;
  color: var(--color-text-primary);

  @include activeOrHover {
    background-color: var(--color-neutral-100-a);
  }
}

.primary {
  background-color: var(--color-interactive-primary);
  color: var(--color-text-primary-inverse);

  @include activeOrHover {
    background-color: var(--color-interactive-primary-hover);
  }

  @include focusOutline(var(--color-border-focus-primary));
}

.transparent {
  background-color: var(--color-background-primary-overlay);
  color: var(--color-text-primary);

  @include activeOrHover {
    background-color: var(--color-background-primary);
  }
}

.disabled,
.disabled:hover {
  background-color: var(--color-background-disabled);
  color: var(--color-text-disabled);
  box-shadow: none;
  cursor: default;
}

.loading,
.loading:hover {
  cursor: default;
}

.plusBadge {
  position: absolute;
  top: 0;
  right: 0;
}
